/* styles.css */
.nav-link {
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  transition: background-color 0.3s, color 0.3s;
}

.nav-link:hover {
  background-color: rgba(255, 255, 255, 0.2);
  color: #fff;
}

.logo {
  height: 40px;
}

button {
  padding: 0.5rem 1rem;
  margin-top: 1rem;
  background-color: #1d4ed8;
  color: white;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
}

button:hover {
  background-color: #aabeeb;
}

select {
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ddd;
  border-radius: 0.25rem;
  width: 100%;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  background-color: #f3f4f6;
  padding: 0.5rem;
  border-radius: 0.25rem;
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bg-red-500 {
  background-color: #ef4444;
}

.bg-red-500:hover {
  background-color: #f87171;
}

.bg-blue-600 {
  background-color: #2563eb;
}

.bg-blue-600:hover {
  background-color: #1d4ed8;
}

/* New styles for dropdown menu */
.navbar {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  border-radius: 0.25rem;
  overflow: hidden;
  top: 100%; /* Ensure the dropdown is positioned below the navbar */
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  transition: background-color 0.3s, color 0.3s;
  cursor: pointer; /* Add cursor pointer */
}

.dropdown-content a:hover {
  background-color: #ddd !important;
  color: #000 !important;
}

.navbar:hover .dropdown-content {
  display: block;
}

.navbar:hover .nav-link {
  background-color: rgba(255, 255, 255, 0.2);
}

.navbar-container {
  display: flex;
  align-items: center;
}

.loader {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  vertical-align: middle;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 100%;
}

.privacy-policy-container {
  margin: 0 auto;
  max-width: 800px;
  padding: 20px;
}

.privacy-policy-content h3 {
  margin-top: 20px;
  font-size: 1.5em;
}

.privacy-policy-content ul {
  margin-left: 20px;
  padding-left: 0;
  list-style-type: none;
  display: block;  /* Ensures the list items are block level */
}

.privacy-policy-content li {
  margin-bottom: 10px;
  padding: 10px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 4px;
  display: block; /* Ensure block level for consistent spacing */
}

.privacy-policy-content p {
  margin-bottom: 15px;
  font-size: 1.1em;
}

.privacy-policy-content h1, .privacy-policy-content h2 {
  margin-bottom: 15px;
}

.privacy-policy-content ul li b {
  font-weight: bold;
}

.info-list, .usage-list, .sharing-list, .rights-list {
  list-style-type: none;
  margin-left: 0;
}

.info-list li, .usage-list li, .sharing-list li, .rights-list li {
  margin-bottom: 15px;
  padding: 12px;
  background: #f3f3f3;
  border-radius: 4px;
}

.bg-green-lighter {
  background-color: #28a745; /* Bright green for better contrast */
}
